import React, { useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Contact.css'; // Importando o CSS do formulário

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Inicializa AOS quando o componente é montado
    useEffect(() => {
        AOS.init();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/mailer.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams(formData),
            });

            if (response.ok) {
                setModalMessage('Mensagem enviada com sucesso!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                setModalMessage('Houve um problema com sua solicitação.');
            }
        } catch (error) {
            setModalMessage('Erro ao enviar a mensagem. Por favor, tente novamente.');
        } finally {
            setIsModalOpen(true); // Abre o modal ao final do processo
        }
    };

    const closeModal = () => {
        setIsModalOpen(false); // Fecha o modal
    };

    return (
        <section className="contact-section" data-aos="fade" data-aos-duration="1000">
            <h2>CONTATO</h2>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Nome:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">E-mail:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Mensagem:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="5"
                    />
                </div>
                <button type="submit" className="centered-button">Enviar</button>
            </form>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                        <button onClick={closeModal}>Fechar</button>
                    </div>
                </div>
            )}
        </section>
    );
}

export default Contact;
