import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Contact from "./components/Contact"; // Importa o componente Contact

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content-container">
          <Routes>
          <Route path="/" element={null} /> {/* Página inicial vazia */}
          <Route path="/contact" element={<Contact />} /> {/* Exibe o formulário de contato ao acessar esta rota */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
